import React from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {
  ChakraProvider,
} from '@chakra-ui/react';

import theme from "./theme/theme";

import { ProtectRoutes } from './_helpers/Auth';

import LoginPage from './views/auth/login/login'

import Page404 from './views/common/404/404'

import AdminLayout from "./layouts/admin";
import Dashboard from "./layouts/dashboard";


function App() {
  return (
    <ChakraProvider theme={theme} toastOptions={{ defaultOptions: { position: 'top-center', isClosable: true, duration: 4000, } }}>
      <Router>
        <Routes>
          <Route exact path="/" element={<Dashboard/>} />
          {/* <Route exact path="/auth/login" element={<Dashboard/>} /> */}
          {/* <Route exact path="/project/entry" element={<EntryPage/>} /> */}

          <Route exact path="/dashboard/*" element={<Dashboard/>} />

          <Route element={ <ProtectRoutes /> }>
            <Route path='/admin/*' element={ <AdminLayout /> } />
          </Route>

          <Route path="*" element={<Page404/>} />
        </Routes>
      </Router>
    </ChakraProvider>
  );
}

export default App;
