import React, { useState } from "react";
import { Flex, Text, Card, VStack, useColorModeValue } from "@chakra-ui/react";
import Information from "../../../views/dashboard/components/information/Information";
import Addressinfo from "../../../views/dashboard/components/addressinfo/Addressinfo";
import DashboardMessage from "../../../views/dashboard/components/dashboardMessage/DashboardMessage";

export default function Default() {
  const [walletAddress, setWalletAddress] = useState(""); // Manage wallet state
  const paleGray = useColorModeValue("secondaryGray.400", "whiteAlpha.100");

  // Function to handle wallet address submission from Information component
  const handleWalletSubmit = (address) => {
    setWalletAddress(address); // Update the wallet address
  };

  return (
    <Flex direction="column" pt={{ base: "130px", md: "80px", xl: "80px" }} w="100%">
      {walletAddress === "" && (
        <DashboardMessage mb="20px"/>
      )}
      <Flex direction={{ base: "column", xl: "row" }} pt="20px" w="100%">
        {/* Pass handleWalletSubmit to Information */}
        <Information onWalletSubmit={handleWalletSubmit} />
        <Card ml={{ base: "0", xl: "20px" }} mb="20px" p="20px 20px 30px" borderRadius="20px" w={{ base: "auto", xl: "450px" }}>
          <VStack align='start' spacing={4}>
          <Text fontSize='2xl' fontWeight='bold' mb='20px'>
            Disclaimer:
          </Text>
          <Text mb='20px'>
            This product is in Beta and the checks provided are for informative purposes only. The Palaidn subnet will need to run for a few months to gather verifiable results. Use this tool with caution and consult other resources if necessary.
          </Text>
          </VStack>
        </Card>
      </Flex>
      {/* Pass updated wallet address to Addressinfo */}
      {walletAddress !== "" && (
        <Addressinfo onWalletSubmit={handleWalletSubmit} wallet={walletAddress} />
      )}
    </Flex>
  );
}
