import React, { useState } from "react";
import {
  Flex,
  FormControl,
  SimpleGrid,
  Text,
  Button,
  Icon,
  useColorModeValue
} from "@chakra-ui/react";
import Card from "../../../../components/card/Card.js";
import InputField from "../../../../components/fields/InputField";
import { BiWallet } from "react-icons/bi";

export default function Information({ onWalletSubmit, ...rest }) {
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "secondaryGray.600";

  const [walletInput, setWalletInput] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    // Call the parent function to update the Addressinfo component
    if (walletInput) {
      onWalletSubmit(walletInput);
    }
  };

  const handleClearData = (event) => {
    event.preventDefault();
    // Call the parent function to update the Addressinfo component
    setWalletInput('');
    onWalletSubmit('');
  };

  return (
    <FormControl>
      <Card mb="20px" {...rest}>
        <Flex direction="column" mb="30px" ms="10px">
          <Text fontSize="xl" color={textColorPrimary} fontWeight="bold">
            Wallet check
          </Text>
          <Text fontSize="md" color={textColorSecondary}>
            Here you can check a wallet against our database.
          </Text>
        </Flex>
        <SimpleGrid
          columns={{ sm: 1, md: 2 }}
          spacing={{ base: "20px", xl: "20px" }}
        >
          <InputField
            mb="0px"
            me="30px"
            id="wallet"
            label="Wallet"
            placeholder="wallet address"
            onChange={(e) => setWalletInput(e.target.value)} // Update wallet input value
            value={walletInput}
          />
        </SimpleGrid>
        <Flex direction="row" mt="24px" mb="30px" ms="10px" alignItems="flex-start">
          <Button
            fontSize="sm"
            variant="brand"
            fontWeight="500"
            h="50"
            w="auto"
            minW="0"
            onClick={handleSubmit}
          >
            <Icon as={BiWallet} w="20px" h="20px" me="10px" />
            Check address
          </Button>
          {walletInput !== '' && (
            <Button
              fontSize="sm"
              color="lightcoral"
              fontWeight="500"
              h="50"
              ml="20px"
              w="auto"
              minW="0"
              onClick={handleClearData}
            >
              <Icon as={BiWallet} w="20px" h="20px" me="10px" />
              Clear data
            </Button>
          )}
        </Flex>
      </Card>
    </FormControl>
  );
}