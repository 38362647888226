// Chakra imports
import {
    Flex,
    SimpleGrid,
    Text,
    useColorModeValue,
    DarkMode,
    Icon,
    VStack
} from "@chakra-ui/react";
import Card from "../../../../components/card/Card.js";
import React from "react";
import { FaShieldAlt, FaExclamationTriangle, FaGavel } from "react-icons/fa";

import palaidnBg from "../../../../assets/img/palaidnbg.png";
  
  export default function Information(props) {
    const { ...rest } = props;
    const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
    const textColorSecondary = useColorModeValue("secondaryGray.600", "gray.400");
  
    return (
      <SimpleGrid columns={{ sm: 1, md: 2 }} spacing={{ base: "20px", xl: "20px" }}>
        
        {/* First Info Box */}
        <DarkMode>
          <Card
            backgroundImage={palaidnBg}
            backgroundSize='cover' // Ensures the image covers the entire card
            backgroundPosition='center' // Centers the image in the middle
            backgroundRepeat='no-repeat' // Ensures the image doesn’t repeat
            pl='30px' // Adds more padding on the left side
            pt='40px'
            >
            <VStack w='50%' align='start' spacing={6}>
                <Text 
                fontSize='2xl' 
                fontWeight='bold' 
                color='white'
                textTransform='uppercase' // Transforms the text to uppercase
                >
                Don’t wait until it’s too late.
                </Text>
                <Text 
                fontSize='2xl' 
                fontWeight='bold' 
                color='white'
                >
                Secure yourself by knowing the history of your assets.
                </Text>
            </VStack>
          </Card>
        </DarkMode>
        {/* Second Info Box */}
        <Card>
          <VStack align='start' spacing={4}>
            <Text fontSize='2xl' fontWeight='bold' mb='20px' color={textColorPrimary}>
              Protect Your Finances:
            </Text>
            <Flex align='center'>
              <Icon as={FaShieldAlt} w='20px' h='20px' me='10px' color='green.500' />
              <Text fontSize='md' color={textColorSecondary}>
                Verify that all funds you receive are clean and above board.
              </Text>
            </Flex>
            <Flex align='center'>
              <Icon as={FaExclamationTriangle} w='20px' h='20px' me='10px' color='yellow.500' />
              <Text fontSize='md' color={textColorSecondary}>
                Steer clear of high-risk transactions that could freeze your assets.
              </Text>
            </Flex>
            <Flex align='center'>
              <Icon as={FaGavel} w='20px' h='20px' me='10px' color='red.500' />
              <Text fontSize='md' color={textColorSecondary}>
                Avoid legal issues by ensuring compliance with financial regulations.
              </Text>
            </Flex>
            <Text fontSize='xl' fontWeight='bold' color={textColorSecondary}>
                Inadequate checks could lead to blocked accounts.
            </Text>
          </VStack>
        </Card>
      </SimpleGrid>
    );
  }