import React from 'react';

import { Icon } from '@chakra-ui/react';
import { MdHome } from 'react-icons/md';
import { BiSolidUser } from 'react-icons/bi';

// Admin Imports
import Dashboard from './views/dashboard/main/main';
import AccountPage from './views/dashboard/account/main';

const routes = [
	// --- Dashboards ---
	// {
	// 	name: 'Dashboard',
	// 	path: '/dashboard',
	// 	icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
	// 	collapse: true,
	// 	items: [
	// 		{
	// 			name: 'Main',
	// 			layout: '/dashboard',
	// 			path: '/home',
	// 			component: DashboardsDefault
	// 		},
	// 		{
	// 			name: '2Main',
	// 			layout: '/dashboard',
	// 			path: '/home2',
	// 			component: DashboardsDefault
	// 		}
	// 	]
	// },
  
	// {
	// 	name: 'Main',
	// 	layout: '/admin',
	// 	path: '/home',
	// 	icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
	// 	collapse: false,
	// 	component: <Dashboard/>
	// },
	{
		name: 'Dashboard',
		layout: '/dashboard',
		path: '/',
		icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
		collapse: false,
		component: <Dashboard/>
	}
];

export default routes;
